<template>
  <ul class="list-group" no-body v-show="!hideSuggestion">
    <div class="list-group-item d-flex" @click="suggestionClicked">
      <i class="ion-android-pin mr-2"></i>
      <p>{{ suggestion.description }}</p>
    </div>
  </ul>
</template>

<script>
export default {
  props: ['suggestion', 'hideSuggestion'],
  methods: {
    suggestionClicked() {
      this.$emit('suggestionClicked', this.suggestion);
    },
  },
};
</script>
